table.xclTable input {
  border: 0px solid #e6e7e9;
  margin: 0;
  background: transparent;
  padding: 0.5rem;
  width: 5rem;
}

/* Chrome, Safari, Edge, Opera */
table.xclTable input::-webkit-outer-spin-button,
table.xclTable input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
table.xclTable input[type=number] {
  -moz-appearance: textfield;
}

table.xclTable th {
  color: #626976;
  background: var(--tblr-border-color-light);
  font-size: .625rem;
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: .04em;
  line-height: 1.6;
  padding: 0.5rem;
  border-color: #d9dbde;
  border-bottom-width: 1px;
}

table.xclTable tr td {
  border-color: #d9dbde;
  border-bottom-width: 1px;
  border-right-width: 1px;
}

table.xclTable tr td.label {
  padding: 0.5rem;
  border-color: #d9dbde;
  border-left-width: 1px;
}

table.xclTable tr td.titleInput {
  border-color: #d9dbde;
  border-left-width: 1px;
}

table.xclTable tr:nth-child(even) {
  background-color: var(--tblr-table-bg);
}

table.xclTable tr:nth-child(odd) {
  background: var(--tblr-table-bg);
}


.table td.demo {
  max-width: 300px;
}

.table td.demo span {
  overflow: hidden;
  text-overflow: ellipsis;
  display: inline-block;
  white-space: nowrap;
  max-width: 100%;
}


.scenario-step-input
{
  /* border: 1px solid #eeeeee;
  border-radius: 4px;
  padding: .4375rem 2.25rem .4375rem .75rem; */
  display: inline;
} 